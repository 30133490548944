/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2023 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

"use client";
import { Heading, View, Flex } from "@adobe/react-spectrum";
import CategoryBar from "./CategoryBar";
import style from './categoryBar.module.scss';
// import "@/styles/colors-light.scss";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
import SearchInput from '@/components/AppBar/SearchInput';
import { sendAdobeAnalyticsPageEvent } from '@/utils/utils';
import { useTranslations } from "next-intl";

export default function CategoryBarContainer() {
    const t = useTranslations("");
    const path = usePathname();
    const routes: Array<string> = path.split("/");

    useEffect(() => {
        const scriptElement = document.getElementById('analyticsjs');
        if (scriptElement) {
            scriptElement.addEventListener('load', sendAnalyticsEventOnScriptLoad);
        }

        return () => {
            if (scriptElement) {
                scriptElement.removeEventListener('load', sendAnalyticsEventOnScriptLoad);
            }
        };
    }, [routes[1]])

    const sendAnalyticsEventOnScriptLoad = () => {
        sendAdobeAnalyticsPageEvent("all");
    };

    if (Array.isArray(routes) && routes.length >= 6) return <div />
    return <View UNSAFE_className={style.categoryBarContainer} isHidden={routes.length <= 2 || path.includes("abuse")}>
        <View UNSAFE_className={style.innerCategory}>
            <Heading UNSAFE_className={style.headingText}>{t("photos_for_inspiration")}</Heading>
            <Flex alignItems={'center'} marginBottom={5}>
                <SearchInput />
            </Flex>
            <CategoryBar />
        </View>
    </View>
}
