/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2023 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

"use client";

import React, { useState, useRef, useEffect } from "react";
import { useParams } from "next/navigation";

import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import { ActionButton, Button, Flex, Tooltip, TooltipTrigger, View } from "@adobe/react-spectrum";
import Link from "next/link";
import style from "./categoryBar.module.scss";
import { usePathname } from "next/navigation";
import { CATEGORY_LIST } from '@/constants/constants'
import { sendAdobeAnalyticsCategoryClickEvent } from '@/utils/utils';
import { useTranslations } from "next-intl";



const CategoryBar = () => {
  const listRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const [data, setData] = useState([]);
  const t = useTranslations("");

  const modifiedData = [...data];

  const firstRef = useRef();
  const lastRef = useRef();

  const { allcategories } = useParams();

  const pathUrl = usePathname();
  const parts = pathUrl.split('/');
  const locale = parts[1];


  useEffect(() => {
    const category = Object.keys(CATEGORY_LIST).map(loc => {
      return {
        id: loc,
        name: t(CATEGORY_LIST[loc])
      }
    });
    setData(category);
  }, [locale, activeButton])


  const handleScroll = () => {

    const rightHandleObserver = (entries: any[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowRightButton(false);
        } else {
          setShowRightButton(true);
        }
      });
    };

    const leftHandleObserver = (entries: any[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowLeftButton(false);
        } else {
          setShowLeftButton(true);
        }
      });
    };

    const lastObserver = new IntersectionObserver(rightHandleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    });

    const firstObserver = new IntersectionObserver(leftHandleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    });

    firstObserver.observe(firstRef.current);
    lastObserver.observe(lastRef.current);
  };

  useEffect(() => {
    handleScroll();
  }, []);

  useEffect(() => {
    setActiveButton(allcategories as string);
  }, [pathUrl]);

  const scrollLeft = () => {
    if (showLeftButton) {
      const list = listRef.current;
      const scrollAmount = -200;
      list.scrollTo({
        left: list.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    const list = listRef.current;
    const scrollAmount = 200;
    list.scrollTo({
      left: list.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  const handleButtonClick = (button: any) => {
    sendAdobeAnalyticsCategoryClickEvent(button);
    setActiveButton(button);
  };

  return (
    <Flex>
      <View marginEnd={10} isHidden={!showLeftButton}>
        <Button
          variant="secondary"
          onPress={scrollLeft}
          UNSAFE_className={style.arrowIcon}
          aria-label="left-arrow"
        >
          <ChevronLeft />
        </Button>
      </View>
      <div className={style.scrollContainer} ref={listRef}>
        <div className={style.scrollDiv}>
          <span ref={firstRef} />
          {modifiedData.map((category) => (
            <Link
              key={category.id}
              href={
                category.id === "all"
                  ? `/${locale}/discover/category/all`
                  : `/${locale}/discover/category/${category.id}`
              }
              passHref
            >
              <div
                key={category.id}
                onClick={() => {
                  handleButtonClick(category.id);
                }}
                className={`${style.categoryBtn} ${activeButton === category.id && style.selected}`}
              >
                <TooltipTrigger containerPadding={0}>
                  <ActionButton UNSAFE_style={{ all: "unset" }}>
                    {category.name}
                  </ActionButton>
                  <Tooltip UNSAFE_className={style.toolTip}>
                    {`${t('photos_of')} ${category.name} ${t('for_inspiration')}`}
                  </Tooltip>
                </TooltipTrigger>
              </div>
            </Link>
          ))}
          <span ref={lastRef} style={{ width: 2 }} />
        </div>
      </div>
      <View marginStart={10} isHidden={!showRightButton}>
        <Button
          variant="secondary"
          onPress={scrollRight}
          UNSAFE_className={style.arrowIcon}
          aria-label="right-arrow"
        >
          <ChevronRight />
        </Button>
      </View>
    </Flex>
  );
};

export default CategoryBar;
