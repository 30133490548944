/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2023 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

"use client";

import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { SearchField } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { SEARCH_PATH_URL } from "@/constants/constants";
import style from './search-input.module.scss';

import { sendAdobeAnalyticsSearchEvent } from '@/utils/utils';
import { useTranslations } from "next-intl";

const SearchInput = () => {
    const t = useTranslations("");
    const router = useRouter();
    const pathUrl = usePathname();

    const param = useSearchParams();
    const [searchText, setSearchText] = useState(param.get('q'));

    const parts = pathUrl.split('/');
    const locale = parts[1];
    
    const handleSerach = (e) => {
        setSearchText(e)
    }
    const onSearch = (query: string) => {
        const encodedQuery = encodeURI(query);
        sendAnalyticsEventOnScriptLoad(encodedQuery);
        router.push(`/${locale}/search?q=${encodedQuery}`);
    };

    useEffect(() => {
        if (!pathUrl.includes(SEARCH_PATH_URL)) {
            setSearchText("")
        }
    }, [locale, pathUrl]);

    const sendAnalyticsEventOnScriptLoad = (encodedQuery: string) => {
        sendAdobeAnalyticsSearchEvent(encodedQuery);
    };

    return (
        <SearchField
            position={'relative'}
            width={"100%"}
            UNSAFE_className={style.searchBar}
            maxWidth={1800}
            onSubmit={(query) => onSearch(query)}
            placeholder={t('search_placeholder')}
            value={searchText}
            onChange={handleSerach}
        />
    );

}

export default SearchInput;
